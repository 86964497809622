import React, { useEffect, useState } from 'react';

const PDFViewer = ({paramUrl}) => {
  const [pdfURL, setPdfURL] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    // Effectuez une requête HTTP GET pour récupérer le PDF depuis le backend PHP
    fetch(apiUrl+'getPdf.php/'+ paramUrl, {
      method: 'GET',
      responseType: 'blob', // Définissez le type de réponse comme un blob (pour les fichiers binaires)
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Créez un objet URL pour le blob de la réponse
        const url = window.URL.createObjectURL(blob);
        setPdfURL(url);
      })
      .catch((error) => {
        console.error('Erreur lors du téléchargement du PDF :', error);
      });
  }, []);

  return (
    <div>
      <h1>Affichage du PDF</h1>
      {pdfURL && (
        <embed src={pdfURL} type="application/pdf" width="100%" height="600px" />
      )}
    </div>
  );
};

export default PDFViewer;
