import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const FunnelChoice = () => {
  const [funnelChoiceData, setFunnelChoiceData] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    // Charger le fichier JSON depuis "/back/dataFunnelChoice.json"
    fetch('/back/dataFunnelChoice.json')
      .then((response) => response.json())
      .then((data) => {
        setFunnelChoiceData(data);
      });
  }, []);

  const choiceFunnelCurrent = funnelChoiceData?.funnels || [];

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <h1>Choix du parcours</h1>
      <div>
        <select onChange={handleOptionChange}>
          <option value="">Sélectionner une option</option>
          {choiceFunnelCurrent.map((funnel) => (
            <option key={funnel.id} value={funnel.id}>
              {funnel.name}
            </option>
          ))}
        </select>
        {selectedOption && (
          <Link to={`/funnel/${selectedOption}`}>Aller à la page suivante</Link>
        )}
      </div>
    </div>
  );
}

export default FunnelChoice;
