import React from "react";
import { useParams } from "react-router-dom";
import PDFViewer from "../components/PdfViewer";

const RapportPage = () => {
    const { selectedOption } = useParams();
    return(<>
        <h1>Rapport</h1>
        <PDFViewer paramUrl={selectedOption}/>
        </>
    )
}

export default RapportPage;