import React, { useEffect, useState } from 'react';
import { json, useNavigate, useParams, withRouter  } from 'react-router-dom';
import Question from '../components/Question';
import axios from 'axios';
import MultiCheckbox from '../components/CheckBox'; // Importez le composant MultiCheckbox
import '../style/style.css'
const Funnel = () => {
  const [funnelData, setFunnelData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [responses, setResponses] = useState({});
  const [skippedSteps, setSkippedSteps] = useState([]); // Étapes sautées
  const [previousSelectResponses, setPreviousSelectResponses] = useState({});
  const [previousResponses, setPreviousResponses] = useState({});
  // Recupère le paramètre de l'url
  const { selectedOption } = useParams();
  let url = "/rapport/"+selectedOption
  const apiUrl = process.env.REACT_APP_API_URL
  let jsonFile 

  switch (selectedOption) {
    case '1':
      jsonFile = "dataFunnelPvMaire.json"
      
      break;
    case '2':
      jsonFile = "dataFunnelAgentSyndIm.json"
      break;  
    case '3': 
      jsonFile = "dataFunnelAgentMagasin.json"
      break;
      case '4': 
      jsonFile = "dataFunnelPvAgentBus.json"
      break;
  }
  useEffect(() => {
    // Charger le fichier JSON depuis "/back/dataFunnel.json"
    fetch(`/back/${jsonFile}`)
      .then((response) => response.json())
      .then((data) => {
        setFunnelData(data);
      });
  }, []);

  const navigate = useNavigate(); 

  const handleButtonClick = (value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [`step-${currentStep}`]: value,
    }));
  
    handleNextStep(value);
  };
  
  const handleNextStep = (value) => {
    const currentStepData = funnelData.steps[currentStep];
    const question = currentStepData.questions[0];
  
    // Stockez les réponses actuelles dans les anciennes réponses
    setPreviousResponses((prevResponses) => ({
      ...prevResponses,
      [`step-${currentStep}`]: responses,
    }));
  
    // Stockez également les réponses des étapes suivantes
    for (let i = currentStep + 1; i < funnelData.steps.length; i++) {
      const nextStepData = funnelData.steps[i];
      const nextQuestion = nextStepData.questions[0];
      setPreviousResponses((prevResponses) => ({
        ...prevResponses,
        [`step-${i}`]: {
          ...responses,
          [`step-${i}-question-${nextQuestion.id}`]: responses[`step-${i}-question-${nextQuestion.id}`],
        },
      }));
    }
    
      // ... le reste de votre code pour gérer le passage à l'étape suivante ...
    
    
    if (question.type === 'button' && question.skipOption && question.skipUntilStep) {
      const skipOptionIndex = question.skipOption.indexOf(value);
      if (skipOptionIndex !== -1) {
        const skipUntilStep = question.skipUntilStep[skipOptionIndex];
        if (skipUntilStep !== undefined) {
          setSkippedSteps((prevSkippedSteps) => [...prevSkippedSteps, [currentStep, skipUntilStep]]);
          console.log("next question: " + currentStep)
          setCurrentStep(skipUntilStep - 1);
          return;
        }
      }
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };
 const handlePrevStep = () => {
  // Stockez les réponses actuelles dans les anciennes réponses
  setPreviousResponses((prevResponses) => ({
    ...prevResponses,
    [`step-${currentStep}`]: responses,
  }));

  // Sinon, revenez simplement à l'étape précédente
  setCurrentStep((prevStep) => prevStep - 1);

  // Restaurez les réponses précédentes pour l'étape précédente
  setResponses(previousResponses[`step-${currentStep - 1}`] || {});
  setPreviousSelectResponses(previousSelectResponses[`step-${currentStep - 1}`] || {});

  // Restaurez également les réponses des étapes suivantes
  for (let i = currentStep; i < funnelData.steps.length; i++) {
    const stepResponses = previousResponses[`step-${i}`] || {};
    setResponses((prevResponses) => ({
      ...prevResponses,
      ...stepResponses,
    }));
  }
};

  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    setResponses((prevResponses) => ({
      ...prevResponses,
      [name]: value,
    }));
  
    // Vérifiez si la question actuelle est de type "select"
    const currentStepData = funnelData.steps[currentStep];
    const question = currentStepData.questions.find(q => q.id === parseInt(name.split('-')[3])); // Trouver la question en fonction de l'ID
    if (question.type === 'select') {
      // Stockez les réponses actuelles dans les réponses de type "select"
      setPreviousSelectResponses((prevSelectResponses) => ({
        ...prevSelectResponses,
        [name]: value,
      }));
    }
  };
  const jsonData = JSON.stringify(responses);
  const headers = {
    'Content-Type': 'application/json',
  };
  const handleSendBack = () => {
    axios.post(apiUrl+'api.php/'+selectedOption, jsonData, { headers })
    .then(response => {
      if(response){
        navigate(url);      } // Traitement de la réponse
    })
    .catch(error => {
      console.error(error); // Gestion des erreurs
    });
      console.log(responses)
  };
  
  if (!funnelData) {
    return <div>Chargement en cours...</div>;
  }
  const handleMultiCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
  
    // Obtenez les options actuellement sélectionnées du state des réponses
    const currentSelectedOptions = responses[name] || [];
  
    // Mettez à jour les options sélectionnées en fonction de l'état actuel
    let updatedSelectedOptions;
    if (checked) {
      // Si la case à cocher est cochée, ajoutez l'option à la liste des options sélectionnées
      updatedSelectedOptions = [...currentSelectedOptions, value];
    } else {
      // Si la case à cocher est décochée, retirez l'option de la liste des options sélectionnées
      updatedSelectedOptions = currentSelectedOptions.filter((option) => option !== value);
    }
  
    // Mettez à jour le state des réponses avec les nouvelles options sélectionnées
    setResponses((prevResponses) => ({
      ...prevResponses,
      [name]: updatedSelectedOptions,
    }));
  };
  
  const currentStepData = funnelData.steps[currentStep];
  if (!currentStepData) {
    return <div>Étape introuvable</div>;
  }
  
  return (
    <div className='funnel-container'>
       <div className='back_button'>
      {currentStep > 0 && (
      <button className="custom-circle-button" onClick={() => handlePrevStep()}>
      <span className="arrow-left">&larr;</span>
      </button>
  )}
</div>
      <div className='question-container'>
        <h2>{currentStepData.title}</h2>
        {currentStepData.questions.map((question) => (
          <div key={question.id}>
            <p>{question.question}</p>
            {question.type !== 'comment' && question.type !== 'button' && question.type !== 'checkbox' && question.type !== 'fin' && (
            <Question 
              currentStep={currentStep}
              question={question}
              responses={responses}
              handleInputChange={handleInputChange}              
            />
        )}
            {question.type === 'button' && (
              <div>
                {question.options.map((option) => (
                  <button key={option} onClick={() => handleButtonClick(option)}>
                    {option}
                  </button>
                ))}
              </div>
            )}


            {question.type === 'comment' && (
              <div>
                <textarea
                  name={`step-${currentStep}-question-${question.id}`}
                  value={responses[`step-${currentStep}-question-${question.id}`] || ''}
                  onChange={handleInputChange}
                />
              </div>
            )}
        {question.type === 'checkbox' && (
  
            <MultiCheckbox
              name={`step-${currentStep}-question-${question.id}`}
              options={question.options}
              selectedOptions={responses[`step-${currentStep}-question-${question.id}`] || []}
              onChange={handleMultiCheckboxChange}
            />

          )}

      {question.type === 'fin' && (
              <div className='none'> </div>
            )}
          </div>
        ))}
      </div>
      <div>
        {currentStep < funnelData.steps.length - 1 ? (
          <button onClick={() => handleNextStep(responses[`step-${currentStep}-question-1`])} className='next-button'>Suivant</button>
        ) : (
          <button onClick={handleSendBack} className='next-button'>Terminer</button>
        )}
      </div>
    </div>
  );
};

export default Funnel;
