import React from "react";

const QuestionSelect = ({name, value, onChange, question}) => {
    return(
        <select
        name={name}
        value={value}
        onChange={onChange}
      >
      <option value="" disabled>choix</option>
        {question.options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
}

export default QuestionSelect;