import React from 'react';

function MultiCheckbox({ name, options, selectedOptions, onChange }) {
  return (
<div className="checkbox-container">
  {options.map((option) => (
    <label key={option} className="checkbox-label">
      <input
        type="checkbox"
        name={name}
        value={option}
        checked={selectedOptions.includes(option)}
        onChange={onChange}
        className="custom-checkbox"
      />
      {option}
      <br />
    </label>
  ))}
</div>

  );
}

export default MultiCheckbox;
