// QuestionInput.js
import React from 'react';

const QuestionInput = ({name, value, type, onChange, min, max }) => {

  return (
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      min={min}
      max={max}
    />
  );
};

export default QuestionInput;
