import React from "react";
import '../style/header.css'
const Header = () => {
    return(
        <div className="container header">
            <img src="/logo-securap.svg" alt="logo-securap" className="img_header"/>
            <p>Bonjour Tom</p>
        </div>
    )
}

export default Header;