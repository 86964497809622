import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Utilisez "Routes" au lieu de "Switch"
import Funnel from './page/Funnel';
import FunnelChoice from './page/FunnelHomePage';
import RapportPage from './page/RapportPage';
const AppRouter = () => {
  return (
    <Router>
      <Routes> {/* Utilisez le composant "Routes" au lieu de "Switch" */}
      {/* /* Page d'accueil */ }
        {/* <Route path="/homepage" element={<HomePage />} />  */}
        {/* <Route path='/confirmation_funnel' element={<ConfirmationFunnel />}/> */}
        {/* Page de funnel */}
        <Route path="/funnel/:selectedOption" element={<Funnel />} /> 
        <Route path="/" element={<FunnelChoice />} />
        <Route path="/rapport/:selectedOption" element={<RapportPage/>} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
