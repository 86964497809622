import React from 'react';
import QuestionInput from './QuestionInput';
import QuestionSelect from './QuestionSelect';
// ... import other question components ...

const Question = ({ question, currentStep, responses, handleInputChange, min, max }) => {
    let type
    const name = `step-${currentStep}-question-${question.id}`
    const value = responses[`step-${currentStep}-question-${question.id}`] || ''
  switch (question.type) {
    case 'text':
        type = 'text'
        break
    case 'date':
        type = 'date'
        break;
    case 'time':
        type = 'time'
        break;
    case 'size':
        type = 'size'
        min = '0'
        max = '250'
        break;
    case 'age':
        type = 'age'
        min='0'
        max = '150'
        break;
    case 'select':
        type = 'select'
        break;
    case 'number': 
        type = 'number'
        break;
  }
  return(
    <>
    {type !== 'select' && (
        <QuestionInput
        name={name}
        value={value}
        type={type}
        currentStep={currentStep}
        question={question}
        responses={responses}
        onChange={handleInputChange}
        min={min}
        max={max}
        />        
    )} 
    {type === 'select' && (
        <QuestionSelect 
            name = {name}
            value = {value}
            onChange={handleInputChange}
            question={question}
        />
    )}
    {type === 'size' && (
        <span> cm</span>
    )}
    {type === 'age' && (
        <span> ans</span>
    )}
   
    </>
  )
};

export default Question;
